<template>
  <div>
  <CCol md="12" lg="12" v-if="$route.name !== 'GrabIntergration' &&
      $route.name !=='DeliveryOnOff'">
    <CCard >
      <CCardBody>
      <CRow>
          <CCol class="d-flex col-9 col-md-10 col-sm-6">
            <img src="/img/icon/store.png" style="width: 32px; height:32px; margin-right: 8px;"/>
            <select
              class="custom-select"
              v-model="shopObjectId"
              @change="setShop()"
            >
              <option selected>{{ $t('selectshop') }}</option>
              <option
                v-for="shop in shops"
                :key="shop.objectId"
                :value="shop.objectId"
              >
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>
          </CCol>
          <CCol class="col-3 col-md-2 col-sm-2 mt-0">
            <CButton block v-on:click="setShop()" color="success">
              {{ $t('submit') }}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard class="shadow-sm">
      <CCardBody style="padding: 0%;">
        <CCard>
          <CCardBody>
            <router-view />
          </CCardBody>
        </CCard>
      </CCardBody>
    </CCard>
  </CCol>
  <router-view  v-if="$route.name === 'GrabIntergration' ||
      $route.name ==='DeliveryOnOff'" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'

export default {
  data() {
    return {
      loadingButton: true,
      loading: true,
    }
  },
  mounted() {
    this.$store.dispatch('getShopSetting')
    this.$store.dispatch('getAllPOS')
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'shopsetting']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    currentPath() {
      return this.$route.name
    },
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
  },
  methods: {
    setShop() {
      this.$store.dispatch('getPermission')
      this.$store.dispatch('getShopSetting')
      if (this.currentPath === 'AdministratorToolsSetting') {
        this.$store.dispatch('getAllPOS')
      } else if (this.currentPath === 'PermissionSetting') {
        this.$store.dispatch('getPermissionSetting')
      } else {
        this.$store.dispatch('getShopSetting')
      }
    },
  },
}
</script>

<style>
.nav-link {
  display: block;
  padding: 0.3rem 0.63rem;
}
</style>
